<template>
    <div>
        <div class="alert alert-success alert-dismissible mail-alert" :class="{ 'show-alert': mailSent || smsSent }">
            <router-link to="#" class="close" @click="closeAlert">&times;</router-link>
            <span class="notification-text">Your smart prescription savings coupon has been sent.</span>
        </div>
        <div class="alert alert-danger alert-dismissible mail-alert"
            :class="{ 'show-alert': mailSendFailure || smsSendFailure }">
            <router-link to="#" class="close text-dark" @click="closeAlert">&times;</router-link>
            <span class="danger-text">Failed to send your prescription savings coupon.</span>
        </div>
        <div id="couponPrint" class="row coupon-wrapper no-gutters">
            <back-to-previous-page class="col-12 col-md-10 offset-md-1" :returnURL="returnURL" backText="Back to Search Results"></back-to-previous-page>
            <h1 class="h2 col-12 col-md-10 offset-md-1 text-center">
                <b class="lead">Show this smart coupon at {{ coupon.pharmacyName }} to claim your
                    savings</b>
            </h1>
            <div class="col-md-10 offset-md-1 col-12 coupon-body">
                <div class="align-items-center d-flex justify-content-between coupon-title">
                    <div>
                        <div class="d-inline-block">
                            <img class="img-fluid" src="../assets/logo-updated-blue.svg" alt="IntelligentRx Logo" />
                            <div class="logo-text d-inline-block">
                                The smart prescription savings coupon
                            </div>
                        </div>
                    </div>
                    <div class="float-right">
                        <div v-if="logoURL" class="">
                            <img :src="logoURL" :alt="coupon.pharmacyName" style="max-height: 100px;" />
                        </div>
                    </div>
                </div>
                <div class="row coupon-details no-gutters">
                    <div class="col-md-7 col-12">
                        <!-- <div class="coupon-info-title">Discount price with this coupon :</div> -->
                        <div class="coupon-price py-2 text-left">
                            <b>${{ price }}</b>
                        </div>
                        <!-- <div class="coupon-info-title">Your prescription:</div> -->
                        <p>
                            {{ coupon.ln }} {{ coupon.qty }} {{ coupon.form
                            }}<span v-if="coupon.dosage">, {{ coupon.dosage }}</span>
                        </p>
                        <div class="row coupon-phone-wrapper">
                            <div class="col-4">
                                <div class="coupon-phone-title">Customer questions call:</div>
                                <div class="coupon-phone-text">{{ coupon.servicePhone }}</div>
                            </div>
                            <div class="col-4">
                                <div class="coupon-phone-title">Pharmacist questions call:</div>
                                <div class="coupon-phone-text">{{ coupon.helpdeskPhone }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 col-12">
                        <div class="row">
                            <div class="col col-6">
                                <div class="coupon-right-info">Bin</div>
                                <div class="coupon-info-text">{{ coupon.rxBin }}</div>
                            </div>
                            <div class="col col-6">
                                <div class="coupon-right-info">PCN</div>
                                <div class="coupon-info-text">{{ coupon.rxPcn }}</div>
                            </div>
                            <div class="col col-6">
                                <div class="coupon-right-info">Member ID</div>
                                <div class="coupon-info-text">{{ coupon.memberId }}</div>
                            </div>
                            <div class="col col-6">
                                <div class="coupon-right-info">Group</div>
                                <div class="coupon-info-text">{{ coupon.rxGroup }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mobile-coupon-phone-wrapper">
                        <div class="row no-gutters">
                            <div class="col-6">
                                <div class="mobile-coupon-phone-title">
                                    Customer questions call:
                                </div>
                                <div class="coupon-phone-text">{{ coupon.helpdeskPhone }}</div>
                            </div>
                            <div class="col-6">
                                <div class="mobile-coupon-phone-title">
                                    Pharmacist questions call:
                                </div>
                                <div class="coupon-phone-text">{{ coupon.servicePhone }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-12 coupon-info-title">
                        <strong>Discount only - This is NOT Insurance.</strong> This is your
                        estimated price at {{ coupon.pharmacyName }}. The pharmacy will
                        provide the exact pricing.
                    </div>
                </div>
            </div>
            
            <h2 class="col-12 py-2 text-center">Save This Coupon</h2>
            <div class="col-md-3 offset-md-1 col-6 p-2">
                <prc-button type="info" class="align-items-center" data-toggle="modal" data-target="#exampleModalCenter"
                    aria-label="Send this coupon to yourself via an email">
                    <span>
                        <font-awesome-icon class="px-2" icon="fa fa-envelope" size="lg" aria-hidden="true" width="24px"
                            height="24px"></font-awesome-icon>
                        <span class="btn-inner-text">Email</span>
                    </span>
                </prc-button>
            </div>

            <div class="col-md-3 col-6 p-2">
                <prc-button type="info" class="align-items-center" data-toggle="modal" data-target="#phoneNumberModal"
                    aria-label="Send this coupon to yourself via a text message">
                    <span>
                        <font-awesome-icon class="px-2" icon="fa fa-comment" size="lg" aria-hidden="true" width="24px"
                            height="24px"></font-awesome-icon>
                        <span class="btn-inner-text">Text</span>
                    </span>
                </prc-button>
            </div>

            <div class="col-md-3 col-6 p-2">
                <prc-button type="info" class="align-items-center" @click="getPrintMethod"
                    aria-label="Download this coupon via the Print dialog">
                    <span>
                        <font-awesome-icon class="px-2" icon="fa fa-download" size="lg" aria-hidden="true" width="24px"
                            height="24px"></font-awesome-icon>
                        <span class="btn-inner-text">Download</span>
                    </span>
                </prc-button>
            </div>

            <h2 class="col-12 py-2 text-center">Pharmacy Tools</h2>
            <div class="col-md-3 offset-md-1 col-6 p-2">
                <prc-button type="info" class="align-items-center" @click="prescriptionTransfer"
                    aria-label="Link to transfer your prescription to this pharmacy">
                    <span>
                        <font-awesome-icon class="px-2" icon="fa fa-arrow-right" size="lg" aria-hidden="true" width="24px"
                            height="24px"></font-awesome-icon>
                        <span class="btn-inner-text">Transfer Prescription</span>
                    </span>
                </prc-button>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col">
                                        <div class="modal-header">
                                            <h1 class="modal-title h5" id="exampleModalCenterTitle">
                                                Enter your email address
                                            </h1>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <prc-input v-model="email" name="email" label="Email Address"
                                            placeholder="example@domain.com" :state="validateEmail"
                                            errorMessage="Please enter a valid email address" />
                                    </div>
                                </div>
                                <div class="row footer">
                                    <div class="col col-md-6 offset-md-6">
                                        <loading-spinner v-if="showSpinner" />
                                        <prc-button type="info" :disabled="!validateEmail" @click="sendEmail" :aria-label="'Send this coupon to yourself at the email address: ' +
                                            email
                                            ">Send
                                        </prc-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="phoneNumberModal" tabindex="-1" role="dialog"
                aria-labelledby="phoneNumberModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col">
                                        <div class="modal-header">
                                            <h1 class="modal-title h5" id="phoneNumberModalTitle">
                                                Enter your phone number
                                            </h1>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <prc-input v-model="sms" name="SMS" label="Phone Number" placeholder="123-456-7890"
                                            :state="validateSMS" errorMessage="Please enter a valid Phone Number" />
                                    </div>
                                </div>
                                <div class="row footer">
                                    <div class="col col-md-6 offset-md-6">
                                        <loading-spinner v-if="showSpinner" />
                                        <prc-button type="info" :disabled="!validateSMS" @click="sendSMS" :aria-label="'Send this coupon to yourself at the phone number: ' +
                                            sms
                                            ">Send
                                        </prc-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import PrcButton from "./controls/prc-button.vue";
import PrcInput from "./controls/prc-input.vue";
import LoadingSpinner from "./controls/loading-spinner.vue";
import $ from "jquery";
import { mixinDetectingMobile } from "../mixins/mixinDetectingMobile";
import { requestmixin } from "../mixins/requestmixin";
import { cookiemixin } from "../mixins/cookiemixin";
import { errormixin } from "../mixins/errormixin";
import { logoURLMixin } from "../mixins/logoURLMixin";
import { coupon } from "./consumer-discount-cards-page.vue";
import BackToPreviousPage from './controls/back-to-previous-page.vue';

export interface couponDetailsPage {
    coupon: coupon
    email: string | null
    sms: string | null
    showSpinner: boolean
    mailSent: boolean
    smsSent: boolean
    mailSendFailure: boolean
    smsSendFailure: boolean
    price: string
    logoURL: string
    returnURL: string
}

export default {
    name: "coupon-details-page",
    mixins: [
        mixinDetectingMobile,
        requestmixin,
        cookiemixin,
        errormixin,
        logoURLMixin,
    ],
    components: {
        PrcButton,
        PrcInput,
        LoadingSpinner,
        BackToPreviousPage,
    },
    data() {
        const d: couponDetailsPage = {
            coupon: {
                dosage: "",
                form: "",
                helpdeskPhone: "",
                ln: "",
                memberId: "",
                pharmacyName: "",
                price: "",
                qty: "",
                returnURL: "",
                rxBin: "",
                rxGroup: "",
                rxPcn: "",
                servicePhone: ""
            },
            email: null,
            sms: null,
            showSpinner: false,
            mailSent: false,
            smsSent: false,
            mailSendFailure: false,
            smsSendFailure: false,
            price: "0",
            logoURL: "",
            returnURL: "",
        };
        return d;
    },
    methods: {
        print() {
            console.log("CLICK_PRINT");
            window.print();
        },
        printMobile() {
            console.log("CLICK_PRINT");
            window.print();
        },
        sendEmail() {
            this.setEmail();
            this.mailSent = false;
            this.toggleSpinner();
            this.post("/api/ecsprice0", {
                coupon: this.coupon,
                receiverEmail: this.email,
            })
                .then(() => {
                    this.showSpinner = false;
                    //@ts-expect-error
                    $("#exampleModalCenter").modal("hide");
                    this.mailSent = true;
                    this.mailSendFailure = false;
                })
                .catch((err: any) => {
                    this.showSpinner = false;
                    //@ts-expect-error
                    $("#exampleModalCenter").modal("hide");
                    this.mailSent = false;
                    this.mailSendFailure = true;
                    this.reportError(err);
                });
        },
        sendSMS() {
            this.setSMS();
            this.smsSent = false;
            this.toggleSpinner();
            this.post("/api/ecssms0", {
                coupon: this.coupon,
                ReceiverPhoneNumber: this.sms,
            })
                .then(() => {
                    this.showSpinner = false;
                    //@ts-expect-error
                    $("#phoneNumberModal").modal("hide");
                    this.smsSent = true;
                    this.smsSendFailure = false;
                })
                .catch((err) => {
                    this.showSpinner = false;
                    //@ts-expect-error
                    $("#phoneNumberModal").modal("hide");
                    this.smsSendFailure = true;
                    this.smsSent = false;
                    this.reportError(err);
                });
        },
        toggleSpinner() {
            this.showSpinner = !this.showSpinner;
        },
        closeAlert() {
            this.mailSent = false;
            this.mailSendFailure = false;
            this.smsSent = false;
            this.smsSendFailure = false;
        },
        readEmail() {
            return this.readCookie("Email");
        },
        setEmail() {
            if (this.email == null || this.email.length < 1) {
                return;
            }
            return this.setCookie("Email", this.email);
        },
        readSMS() {
            return this.readCookie("SMS");
        },
        setSMS() {
            if (this.sms == null || this.sms.length < 1) {
                return;
            }
            return this.setCookie("SMS", this.sms);
        },
        couponToCSV() {
            // sort object's properties alphabetically so their positions in the list are consistent
            var sortedProperties = [];
            for (const key in this.coupon) {
                sortedProperties.push([key, this.coupon[key]]);
            }
            sortedProperties.sort(function (a, b) {
                if (a[0] < b[0]) {
                    return -1;
                }
                if (a[0] > b[0]) {
                    return 1;
                }
                return 0;
            });
            let sortedCSV = "";
            for (let i = 0; i < sortedProperties.length; i++) {
                if (i != 0) {
                    sortedCSV += ",";
                }
                sortedCSV += sortedProperties[i][1];
            }
            return sortedCSV;
        },
        prescriptionTransfer() {
            const url = `https://dev.intelligentrx.com/prescription-transfer?p=${this.coupon.pharmacyName}`
            window.open(url, '_blank');
        },
        // log(eventToLog) {
        //   // note: there is a similar switch in CacheService.cs
        //   let pbm = "Retail";
        //   switch (this.coupon.rxBin) {
        //     case "006053":
        //       pbm = "ScriptSave";
        //       break;
        //     case "017713":
        //     case "019819":
        //     case "023534":
        //     case "610308":
        //     case "610711":
        //     case "610186":
        //     case "610312":
        //     case "013849":
        //       pbm = "Axe";
        //       break;
        //     case "TODO":
        //       pbm = "Optum";
        //       break;
        //     case "021148":
        //       pbm = "ChangeHealthcare";
        //       break;
        //     case "003585":
        //       pbm = "MedImpact";
        //       break;
        //     case "":
        //     case "Retail":
        //     case "retail":
        //     default:
        //       pbm = "Retail";
        //       break;
        //   }
        //   const urlParams = new URLSearchParams(document.location.search);
        // eslint-disable-next-line no-undef
        //   amplitude.getInstance().logEvent(eventToLog, {
        //     URL: document.location.toString(),
        //     drugName: this.coupon.ln,
        //     pharmacyName: this.coupon.pharmacyName,
        //     form: this.coupon.form,
        //     qty: this.coupon.qty,
        //     dosage: this.coupon.dosage,
        //     rxBin: this.coupon.rxBin,
        //     rxPcn: this.coupon.rxPcn,
        //     PBM: pbm,
        //     gclid: urlParams.get("gclid"), // google click id
        //     memberId: this.coupon.memberId,
        //   });
        // },
    },
    beforeMount() {
        this.coupon = this.$route.query;
        this.returnURL = this.coupon.returnURL;
        this.price = parseFloat(this.coupon.price).toFixed(2);
        let emailFromCookie = this.readEmail();
        if (emailFromCookie != null && emailFromCookie.length > 0) {
            this.email = emailFromCookie;
        }
        let smsFromCookie = this.readSMS();
        if (smsFromCookie != null && smsFromCookie.length > 0) {
            this.sms = smsFromCookie;
        }
    },
    created() {
        let noIndex = document.querySelector('head meta[name="robots"]');
        if (noIndex == null) {
            let headEl = document.querySelector("head");
            if (headEl == null) return;
            headEl.insertAdjacentHTML(
                "beforeend",
                '<meta name="robots" content="noindex">'
            );

        }
        else noIndex.setAttribute("content", "noindex");
    },
    mounted() {
        // CouponsViewed is a CSV file where the columns are properties in this.coupon:
        //
        // a,b,c,d,e,f
        //
        // a,b,c,d,e,f,,,a2,b2,c2,d2,e2,f2
        //
        // 40 MG,Tablet,1-800-404-1031,ATORVASTATIN CALCIUM,20211015,CUB PHARMACY,8.25,30,006053,RX2002,MSC,1-800-407-8156
        let existingCouponList = this.getCookie("CouponsViewed");
        var newCouponList;
        if (existingCouponList == null || existingCouponList.length == 0) {
            newCouponList = this.couponToCSV();
        } else {
            newCouponList = existingCouponList + ",,," + this.couponToCSV();
        }
        if (newCouponList.length > 1_000) {
            // remove the oldest cookie if our history is too long
            let cutTo = newCouponList.indexOf(",,,");
            newCouponList = newCouponList.substring(cutTo);
        }
        this.setCookie("CouponsViewed", newCouponList);
        this.logoURL = this.getLogoURL(this.coupon.pharmacyName);

        if (location.host !== "intelligentrx.com") {
            // Do not log stats if this is not the prod website
            return;
        }

        // note: there is a similar switch in CacheService.cs
        let pbm = "Retail";
        switch (this.coupon.rxBin) {
            case "006053":
                pbm = "ScriptSave";
                break;
            case "017713":
            case "019819":
            case "023534":
            case "610308":
            case "610711":
            case "610186":
            case "610312":
            case "019827":
            case "013849":
                pbm = "Axe";
                break;
            case "TODO":
                pbm = "Optum";
                break;
            case "021148":
                pbm = "ChangeHealthcare";
                break;
            case "003585":
                pbm = "MedImpact";
                break;
            case "":
            case "Retail":
            case "retail":
            default:
                pbm = "Retail";
                break;
        }
        const urlParams = new URLSearchParams(document.location.search);
        // eslint-disable-next-line no-undef
        // amplitude.getInstance().logEvent("COUPON_PAGE_LOAD", {
        //   URL: document.location.toString(),
        //   drugName: this.coupon.ln,
        //   pharmacyName: this.coupon.pharmacyName,
        //   form: this.coupon.form,
        //   qty: this.coupon.qty,
        //   dosage: this.coupon.dosage,
        //   rxBin: this.coupon.rxBin,
        //   rxPcn: this.coupon.rxPcn,
        //   PBM: pbm,
        //   gclid: urlParams.get("gclid"), // google click id
        //   memberId: this.coupon.memberId,
        // });
        this.post("/api/couponview", {
            coupon: this.coupon,
            memberId: this.coupon.memberId,
            returnURL: this.returnURL,
            gclid: urlParams.get("gclid"), // google click id
            PBM: pbm,
        });
        // do not trigger event unless someone actually had time to read the coupon
        // this also is nice because it gives rdt more time to load
        // eslint-disable-next-line no-undef
        // setTimeout(rdt("track", "Purchase", {
        //   itemCount: 1,
        //   value: 1,
        //   currency: "USD",
        // }), 200);
    },
    computed: {
        validateEmail() {
            //basic email regex validation
            const regex =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regex.test(String(this.email).toLowerCase());
        },
        validateSMS() {
            // first reduce the phone number in the same way the server will
            if (this.sms == null || this.sms.length < 2) {
                return false;
            }
            let reducedPhoneNumber = this.sms.replaceAll(/[^+0-9]/g, "");
            if (reducedPhoneNumber.length < 2) {
                return false;
            }
            if (reducedPhoneNumber.length != 12) {
                reducedPhoneNumber = "+1" + reducedPhoneNumber;
            }
            return (
                reducedPhoneNumber.length == 12 &&
                reducedPhoneNumber[0] == "+" &&
                reducedPhoneNumber[1] == "1" &&
                reducedPhoneNumber[2] != "+"
            );
        },
        getPrintMethod() {
            return this.isMobile() ? this.printMobile : this.print;
        },
    },
};
</script>

<style scoped>
.alert-success {
    background: #51a74e 0% 0% no-repeat padding-box;
    border: 1px solid #0da23b;
    opacity: 1;
}

.notification-text {
    text-align: left;
    font: var(--body-font-weight) 18px/22px var(--body-font-family);
    letter-spacing: 0.45px;
    color: #ffffff;
    opacity: 1;
    margin-left: 8.33%;
}

.danger-text {
    text-align: left;
    font: var(--body-font-weight) 18px/22px var(--body-font-family);
    letter-spacing: 0.45px;
    opacity: 1;
    margin-left: 8.33%;
}

.alert-dismissible .close {
    color: #ffffff;
    opacity: 1;
}

.btn-inner-text {
    text-align: left;
    font: Bold 19px/23px var(--body-font-family);
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
}

.coupon-wrapper {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 16px 0 60px 0;
}

.coupon-body {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #4f5e74b3;
    border-radius: 4px;
    opacity: 1;
}

.logo-text {
    padding: 8px 0px 0px 13px;
    text-align: left;
    font: Bold 18px var(--heading-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
}

.coupon-right-info {
    text-align: left;
    font: var(--body-font-weight) 14px/22px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525cc;
    opacity: 1;
    margin-top: 15px;
}

.coupon-info-title {
    text-align: left;
    font: var(--body-font-weight) 14px/22px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525cc;
    opacity: 1;
    margin: 22px 0 10px 0;
}

.coupon-info-text {
    text-align: left;
    font: bold 21px/30px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
    margin-bottom: 14px;
}

.coupon-price {
    text-align: left;
    font-weight: var(--body-font-weight);
    font-size: 32px;
    font-family: var(--body-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
    margin-bottom: 10px;
}

.coupon-phone-title {
    text-align: left;
    font: var(--body-font-weight) 16px/22px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
}

.coupon-phone-text {
    text-align: left;
    font: var(--body-font-weight) 16px/19px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
}

.coupon-title {
    border-bottom: 0.5px solid #4f5e74b3;
    padding: 20px 30px;
}

.coupon-details {
    padding-left: 30px;
}

.coupon-phone-wrapper {
    margin-top: 20px;
}

.modal-header {
    border-bottom: none;
    padding-left: 0;
    padding-right: 0;
}

.footer {
    padding: 20px 0px 20px 0px;
}

.mail-alert {
    display: none;
}

.mobile-coupon-phone-wrapper {
    display: none;
}

.show-alert {
    display: block !important;
}

@media (max-width: 992px) {
    .align-items-center.d-flex.coupon-title {
        display: block !important;
        text-align: center;
        padding: 20px 10px;
    }

    .coupon-right-item {
        padding: 0;
    }

    .mobile-coupon-phone-wrapper {
        display: inline;
        border-top: 0.5px solid #4f5e74b3;
        padding-top: 10px;
    }

    .coupon-phone-wrapper {
        display: none;
    }

    .coupon-details {
        padding: 0 15px 30px 15px;
    }

    .mobile-coupon-phone-title {
        text-align: left;
        font: var(--body-font-weight) 12px/18px var(--body-font-family);
        letter-spacing: 0px;
        color: #29252599;
        opacity: 1;
    }

    .coupon-wrapper {
        padding: 16px 15px 80px 15px;
    }

    .mail-alert {
        padding: 5px 40px 5px 15px;
        margin: 0;
    }

    .notification-text {
        text-align: left;
        font: var(--body-font-weight) 12px/15px var(--body-font-family);
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin: 0;
    }

    .danger-text {
        text-align: left;
        font: var(--body-font-weight) 12px/15px var(--body-font-family);
        letter-spacing: 0px;
        opacity: 1;
        margin: 0;
    }

    .mail-alert .close {
        padding-top: 5px;
    }

    .logo-text {
        text-align: center;
        font: var(--body-font-weight) 15px var(--body-font-family);
        letter-spacing: 0px;
        color: #292525;
        opacity: 1;
    }
}
</style>